@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;200;300;400;500;600;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #354d615b;
}

*::-webkit-scrollbar-thumb {
  background-color: #354d61;
  border-radius: 1px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 137, 128, 1);
}

body {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans', sans-serif;
}

h1 {
  color: #354d61;
}

h3 {
  color: #8496a1;
}

h4 {
  text-indent: 24px;
  text-decoration: underline;
}

a {
  color: #354d61;
}

a:hover {
  color: #4d6a81;
}

img {
  border-radius: 6px;
}

.ant-card img {
  border-radius: none;
}

code {
  font-family: 'Noto Sans Mono', monospace;
}

p {
  text-align: justify;
}

figure {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.ant-layout {
  background: #f9fafb;
}

.ant-layout-header {
  background: white;
  height: auto;
  padding: 0;
  line-height: initial;
}

.ant-layout-footer {
  color: white;
  background: #8496a1;
  padding: 24px 0 0;
}

.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #8496a1;
}

.ant-menu-submenu-popup li:hover > span {
  color: #8496a1;
}

@media screen and (min-width: 601px) {
  .smartphone-only {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .laptop-only {
    display: none !important;
  }

  .revert {
    flex-direction: column-reverse;
  }
}

.ant-btn-primary {
  border-color: #354d61;
  background: #354d61;
}

.ant-btn-primary:hover {
  border-color: #4d6a81;
  background: #4d6a81;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #8496a1;
}
.ant-menu-submenu-selected {
  color: #91a7b4 !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #8496a1;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #8496a1;
}

.ant-menu-item-selected {
  color: #8496a1;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #8496a13a;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #8496a13a;
}

.ant-menu-submenu-popup > ul:first-child {
  border-radius: 6px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.ant-menu {
  background-color: white;
}

.ant-modal,
.ant-modal-content {
  border-radius: 12px !important;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.ant-alert {
  border-radius: 6px;
}

.ant-card {
  border-radius: 6px;
  overflow: hidden;
}
